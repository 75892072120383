
import Vue, { PropType } from "vue";

import http from "@/http";

import {
  ContractHistory,
  Member,
  Notice,
  ResidenceHistory,
} from "@/interfaces";

import MyOverview from "@/components/member/MyOverview.vue";
import MyTable from "@/components/notice/MyTable.vue";

import { date } from "@/filters";

import { mdiPlus } from "@mdi/js";
import dayjs from "dayjs";

export interface DataType {
  notices: Notice[];
}

export default Vue.extend({
  components: {
    MyOverview,
    MyTable,
  },
  filters: {
    date,
  },
  props: {
    member: {
      type: Object as PropType<Member>,
      required: true,
    },
  },
  data(): DataType {
    return {
      notices: [],
    };
  },
  computed: {
    icon() {
      return {
        mdiPlus,
      };
    },
    inContract(): boolean {
      return this.member.contract_histories.length > 0;
    },
    inResidence(): boolean {
      return this.member.residence_histories.length > 0;
    },
    contractHistories(): ContractHistory[] {
      return this.member.contract_histories.filter((history) => {
        const end = dayjs(history.end_date);
        return dayjs().isBefore(end);
      });
    },
    residenceHistories(): ResidenceHistory[] {
      return this.member.residence_histories.filter((history) => {
        const end = dayjs(history.end_date);
        return dayjs().isBefore(end);
      });
    },
  },
  async mounted() {
    const url = `/members/${this.member.id}/notices`;

    const { data } = await http.get<Notice[]>(url);

    this.notices = data;
  },
  methods: {
    async click() {
      if (!confirm("本当に登録解除しますか?")) {
        return;
      }
      const url = `/members/${this.member.id}`;

      await http.delete(url);

      this.member.user = undefined;
    },
    async login() {
      if (!this.member.user) {
        return;
      }

      const { data } = await http.get(`users/${this.member.user.id}`);

      window.location.href = data.login_link;
    },
  },
});
